import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Chip,
  Paper,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Container,
  Fade,
  Zoom,
  Button,
  Stack,
  alpha,
  Typography as MuiTypography,
  useScrollTrigger,
  Slide,
  Fab,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Items, categories } from "../aliwan3/items";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import CakeIcon from "@mui/icons-material/Cake";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ScrollTop = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

const RestaurantMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const getCategoryIcon = (categoryId) => {
    switch (categoryId) {
      case 1:
        return <LocalDiningIcon />;
      case 2:
        return <RestaurantIcon />;
      case 3:
        return <EmojiFoodBeverageIcon />;
      case 4:
        return <CakeIcon />;
      default:
        return <FastfoodIcon />;
    }
  };

  const drawer = (
    <Box sx={{ width: 280, bgcolor: "background.paper", height: "100%" }}>
      <Box
        sx={{
          p: 4,
          textAlign: "center",
          bgcolor: alpha(theme.palette.primary.main, 0.05),
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: "10%",
            width: "80%",
            height: "1px",
            bgcolor: alpha(theme.palette.primary.main, 0.2),
          },
        }}
      >
        <img
          src={require("../aliwan3/images/logo.png")}
          alt="Restaurant Logo"
          style={{ height: "60px", marginBottom: "16px" }}
        />
        <Typography
          variant="h6"
          sx={{ color: "primary.main", fontWeight: 600 }}
        >
          مطعم الإيوان
        </Typography>
      </Box>
      <List sx={{ px: 2, py: 3 }}>
        {categories.map((category) => (
          <ListItem
            button
            key={category.id}
            selected={selectedCategory === category.id}
            onClick={() => handleCategorySelect(category.id)}
            sx={{
              borderRadius: 2,
              mb: 1,
              "&.Mui-selected": {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
                "&:hover": {
                  bgcolor: alpha(theme.palette.primary.main, 0.15),
                },
                "& .MuiListItemIcon-root": {
                  color: "primary.main",
                },
                "& .MuiListItemText-primary": {
                  color: "primary.main",
                  fontWeight: 600,
                },
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              {getCategoryIcon(category.id)}
            </ListItemIcon>
            <ListItemText
              primary={category.name}
              primaryTypographyProps={{
                fontWeight: selectedCategory === category.id ? 600 : 400,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#fafafa",
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          height: "60vh",
          minHeight: "400px",
          width: "100%",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
            zIndex: 1,
          },
        }}
      >
        <Box
          component="img"
          src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
          alt="Restaurant Interior"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            zIndex: 2,
            width: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
            }}
          >
            مطعم الإيوان
          </Typography>
          <Typography
            variant="h5"
            sx={{
              maxWidth: "600px",
              mx: "auto",
              textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
            }}
          >
            استمتع بأشهى المأكولات الشرق أوسطية في أجواء أنيقة
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          overflowX: "auto",
          mb: 4,
          px: 2,
          gap: 2,
        }}
      >
        {categories.map((category) => (
          <Box
            key={category.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 120,
              m: 2,
              textAlign: "center",
            }}
            onClick={() => setSelectedCategory(category.id)}
          >
            <Box
              component="img"
              src={category.image || "https://placehold.co/600x400/EEE/31343C"}
              alt={category.name}
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                objectFit: "cover",
                border: "3px solid white",
                boxShadow: 3,
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                  boxShadow: 6,
                },
              }}
            />
            <Typography 
              variant="h6" 
              sx={{
                mt: 1,
                fontWeight: 600,
                color: "text.primary",
                whiteSpace: "nowrap",
              }}
            >
              {category.name}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", flex: 1, pt: 8 }}>
        {!isMobile && (
          <Paper
            sx={{
              width: 280,
              flexShrink: 0,
              height: "calc(100vh - 64px)",
              overflow: "auto",
              borderRight: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              position: "fixed",
              top: 64,
            }}
          >
            {drawer}
          </Paper>
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: "#fafafa",
            minHeight: "calc(100vh - 64px)",
            ml: !isMobile ? "280px" : 0,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
                Our Menu
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ maxWidth: "600px", mx: "auto" }}
              >
                Discover our carefully curated selection of delicious dishes,
                prepared with the finest ingredients
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {Items.item
                .filter(
                  (item) =>
                    selectedCategory === 0 || item.category === selectedCategory
                )
                .map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Zoom
                      in
                      timeout={300}
                      style={{ transitionDelay: `${index * 100}ms` }}
                    >
                      <Link
                        to={`${item.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            transition: "all 0.3s ease-in-out",
                            bgcolor: "background.paper",
                            borderRadius: 2,
                            overflow: "hidden",
                            "&:hover": {
                              transform: "translateY(-8px)",
                              boxShadow: theme.shadows[8],
                              "& .MuiCardMedia-root": {
                                transform: "scale(1.05)",
                              },
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="280"
                            image={item.image}
                            alt={item.title}
                            sx={{
                              objectFit: "cover",
                              transition: "transform 0.3s ease-in-out",
                            }}
                          />
                          <CardContent sx={{ flexGrow: 1, p: 3 }}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              sx={{
                                fontWeight: 600,
                                mb: 1,
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                mb: 2,
                                lineHeight: 1.6,
                              }}
                            >
                              {item.description}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: "auto",
                              }}
                            >
                              <Chip
                                label={`${item.price} ريال`}
                                color="primary"
                                variant="outlined"
                                sx={{
                                  fontWeight: 600,
                                  "& .MuiChip-label": {
                                    px: 2,
                                  },
                                }}
                              />
                              <Chip
                                icon={getCategoryIcon(item.category)}
                                label={
                                  categories.find((c) => c.id === item.category)
                                    ?.name
                                }
                                size="small"
                                sx={{
                                  bgcolor: alpha(
                                    theme.palette.primary.main,
                                    0.1
                                  ),
                                  "& .MuiChip-icon": {
                                    color: "primary.main",
                                  },
                                }}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Zoom>
                  </Grid>
                ))}
            </Grid>
          </Container>
        </Box>
      </Box>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>

      <ScrollTop>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  );
};

export default RestaurantMenu;
