import Merchant from "./merchant";
import Admin from "./admin";
import Visitor from "./visitor";
import Aliwan from "./visitor/aliwan";
import Aliwan2 from "./visitor/aliwan2";
import Aliwan3 from "./visitor/aliwan3";
import Aliwan4 from "./visitor/aliwan4";

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  // Extract subdomain from hostname
  const subdomain = window.location.hostname.split(".")[0];

  // If the subdomain is "aliwan", render Visitormenu
  if (subdomain === "aliwan") {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Aliwan />} />
        </Routes>
      </Router>
    );
  }

  // Default case for other subdomains or no subdomain
  return (
    <Router>
      <Routes>
        <Route path="Merchant/*" element={<Merchant />} />
        <Route path="Admin" element={<Admin />} />
        <Route path="/" element={<Visitor />} />
        <Route path="aliwan/*" element={<Aliwan />} />
        <Route path="aliwan2/*" element={<Aliwan2 />} />
        <Route path="aliwan3/*" element={<Aliwan3 />} />
        <Route path="aliwan4/*" element={<Aliwan4 />} />
      </Routes>
    </Router>
  );
}

export default App;
