import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Chip,
  Paper,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Container,
  Fade,
  Zoom,
  Button,
  Stack,
  alpha,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Items, categories } from '../aliwan3/items';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import CakeIcon from '@mui/icons-material/Cake';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';

const RestaurantMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const getCategoryIcon = (categoryId) => {
    switch (categoryId) {
      case 1:
        return <LocalDiningIcon />;
      case 2:
        return <RestaurantIcon />;
      case 3:
        return <EmojiFoodBeverageIcon />;
      case 4:
        return <CakeIcon />;
      default:
        return <FastfoodIcon />;
    }
  };

  const drawer = (
    <Box sx={{ width: 250, bgcolor: 'background.paper' }}>
      <Box sx={{ p: 3, textAlign: 'center', bgcolor: alpha(theme.palette.primary.main, 0.05) }}>
        <img
          src={require('../aliwan3/images/logo.png')}
          alt="Restaurant Logo"
          style={{ height: '50px' }}
        />
      </Box>
      <Divider />
      <List>
        {categories.map((category) => (
          <ListItem
            button
            key={category.id}
            selected={selectedCategory === category.id}
            onClick={() => handleCategorySelect(category.id)}
            sx={{
              '&.Mui-selected': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.15),
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: selectedCategory === category.id ? 'primary.main' : 'inherit' }}>
              {getCategoryIcon(category.id)}
            </ListItemIcon>
            <ListItemText 
              primary={category.name}
              primaryTypographyProps={{
                fontWeight: selectedCategory === category.id ? 600 : 400,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: '#fafafa' }}>
      <AppBar 
        position="static" 
        sx={{ 
          backgroundColor: 'white', 
          boxShadow: 1,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ px: { xs: 1 } }}>
            {isMobile && (
              <IconButton
                color="primary"
                edge="start"
                onClick={() => setMobileOpen(!mobileOpen)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src={require('../aliwan3/images/logo.png')}
              alt="Restaurant Logo"
              style={{ height: '45px', marginRight: '16px' }}
            />
            <Typography 
              variant="h6" 
              sx={{ 
                flexGrow: 1, 
                color: 'text.primary',
                fontWeight: 600,
                letterSpacing: '0.5px',
              }}
            >
              مطعم الإيوان
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton color="primary">
                <SearchIcon />
              </IconButton>
              <IconButton color="primary">
                <ShoppingCartIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="primary"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleMenuClose}>Home</MenuItem>
              <MenuItem onClick={handleMenuClose}>About Us</MenuItem>
              <MenuItem onClick={handleMenuClose}>Contact</MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>

      <Box sx={{ display: 'flex', flex: 1 }}>
        {!isMobile && (
          <Paper
            sx={{
              width: 280,
              flexShrink: 0,
              height: 'calc(100vh - 64px)',
              overflow: 'auto',
              borderRight: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            }}
          >
            {drawer}
          </Paper>
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: '#fafafa',
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              {Items.item
                .filter((item) => selectedCategory === 0 || item.category === selectedCategory)
                .map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Zoom in timeout={300} style={{ transitionDelay: `${index * 100}ms` }}>
                      <Link to={`${item.id}`} style={{ textDecoration: 'none' }}>
                        <Card
                          sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'all 0.3s ease-in-out',
                            bgcolor: 'background.paper',
                            '&:hover': {
                              transform: 'translateY(-8px)',
                              boxShadow: theme.shadows[8],
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="240"
                            image={item.image}
                            alt={item.title}
                            sx={{ 
                              objectFit: 'cover',
                              borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            }}
                          />
                          <CardContent sx={{ flexGrow: 1, p: 3 }}>
                            <Typography 
                              gutterBottom 
                              variant="h6" 
                              component="h2"
                              sx={{ 
                                fontWeight: 600,
                                mb: 1,
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ 
                                mb: 2,
                                lineHeight: 1.6,
                              }}
                            >
                              {item.description}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 'auto',
                              }}
                            >
                              <Chip
                                label={`${item.price} ريال`}
                                color="primary"
                                variant="outlined"
                                sx={{ 
                                  fontWeight: 600,
                                  '& .MuiChip-label': {
                                    px: 2,
                                  },
                                }}
                              />
                              <Chip
                                icon={getCategoryIcon(item.category)}
                                label={categories.find(c => c.id === item.category)?.name}
                                size="small"
                                sx={{ 
                                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                                  '& .MuiChip-icon': {
                                    color: 'primary.main',
                                  },
                                }}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Zoom>
                  </Grid>
                ))}
            </Grid>
          </Container>
        </Box>
      </Box>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default RestaurantMenu; 