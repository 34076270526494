import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Box, CardMedia } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Items} from "./items";

const ItemDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const menuItems = [
    { id: 1, title: 'Margherita', description: 'Classic pizza with fresh basil', price: '$12', image: 'https://via.placeholder.com/300' },
    { id: 2, title: 'Cheeseburger', description: 'Juicy burger with cheese', price: '$10', image: 'https://via.placeholder.com/300' },
    { id: 3, title: 'Carbonara', description: 'Rich pasta with pancetta', price: '$14', image: 'https://via.placeholder.com/300' },
    { id: 4, title: 'Tiramisu', description: 'Coffee-flavored dessert', price: '$8', image: 'https://via.placeholder.com/300' },
    { id: 5, title: 'Lemonade', description: 'Refreshing lemon drink', price: '$5', image: 'https://via.placeholder.com/300' },
  ];

  const item = Items.item.find((item) => item.id.toString() === id);

  const styles = {
    appBar: {
      backgroundColor: '#333',
    },
    container: {
      padding: '16px',
      textAlign: 'center',
    },
    media: {
      height: 300,
      width: '100%',
      objectFit: 'cover',
      marginBottom: '16px',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    price: {
      fontSize: '1.25rem',
      color: '#555',
    },
  };

  return (
    <>
      {/* AppBar */}
      <AppBar position="static" style={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(-1)} // Navigate back
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Item Details
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Item Details Content */}
      <Box style={styles.container}>
        <CardMedia
          component="img"
          image={item.image}
          alt={item.title}
          style={styles.media}
        />
        <Typography style={styles.title}>{item.title}</Typography>
        <Typography style={styles.price}>{item.price}</Typography>
      </Box>
    </>
  );
};

export default ItemDetails;
